<template>
  <column-layout class="page" no-right>
    <!-- Left -->
    <template v-slot:left>
      <UserCard v-if="user" :user="user" class="mb-7" />
      <Menu />
    </template>

    <!-- Main -->
    <template>
      <div class="widget card pa label-empty">
        <!-- Header -->
        <div class="tab-buttons">
          <v-btn
            :to="{ name: 'app.follows.followers' }"
            :color="isFollowers ? 'primary' : 'secondary'"
            rounded
            class="tab-button"
          >
            {{ $t("users.followers") }}
          </v-btn>

          <v-btn
            :to="{ name: 'app.follows.following' }"
            :color="isFollowing ? 'primary' : 'secondary'"
            rounded
            class="tab-button"
          >
            {{ $t("users.following") }}
          </v-btn>

          <v-btn
            :to="{ name: 'app.follows.suggested' }"
            :color="isSuggested ? 'primary' : 'secondary'"
            rounded
            class="tab-button"
          >
            {{ $t("feed.suggested") }}
          </v-btn>

          <v-spacer />

          <!-- Search input -->
          <SearchInput
            v-if="isFollowers || isFollowing"
            v-model="searchInput"
            class="rounded-lg"
            style="max-width: 250px"
            @search="
              ($e) => {
                resetData();
                fetchData();
              }
            "
          />
        </div>

        <v-divider class="my-5" />

        <!-- spinner -->
        <spinner v-if="$loading(`follows/${this.action}`)" center />

        <!-- Info boxes -->
        <template v-if="!$loading(`follows/${this.action}`)">
          <v-alert
            v-if="searchInput && !users.length"
            type="info"
            text
            class="mb-0"
          >
            {{ $t("users.noResults") }}
          </v-alert>

          <v-alert v-else-if="!users.length" type="info" text class="mb-0">
            {{
              this.isFollowers
                ? $t("users.noFollowers")
                : this.isFollowing
                ? $t("users.noFollowings")
                : $t("users.noSuggested")
            }}
          </v-alert>
        </template>

        <!-- List -->
        <div class="user__list_container" style="gap: 20px">
          <UserItem
            v-for="item in users"
            :key="item.id"
            :user="item"
            @show-user-card="displayUser"
            class="mb-5"
          />
        </div>

        <!-- Load more -->
        <div v-if="$utils.canLoadMore(meta)" class="text-center mt-5 w-100">
          <v-btn
            color="primary"
            small
            rounded
            @click="fetchData"
            :loading="!!$loading(`follows/${this.action}`)"
          >
            {{ $t("common.loadMore") }}
          </v-btn>
        </div>
      </div>
    </template>
  </column-layout>
</template>

<script>
import Menu from "@/components/app/Menu.vue";
import UserItem from "@/components/app/users/UserItem.vue";
import UserCard from "@/components/app/users/UserCard.vue";
import SearchInput from "@/components/common/SearchInput.vue";

export default {
  metaInfo() {
    return {
      title: "Follows",
    };
  },

  components: { Menu, UserItem, UserCard, SearchInput },

  props: {
    type: String,
  },

  data: () => ({
    searchInput: "",
    users: [],
    user: null,
    meta: {
      current_page: 0,
    },
  }),

  computed: {
    tab() {
      return this.$route.meta.type;
    },

    isFollowers() {
      return this.tab === "followers";
    },

    isFollowing() {
      return this.tab === "following";
    },

    isSuggested() {
      return this.tab === "suggested";
    },

    action() {
      return this.isFollowers
        ? "fetchFollowers"
        : this.isFollowing
        ? "fetchFollowings"
        : "fetchSuggestions";
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      if (this.$loading(`follows/${this.action}`)) {
        return;
      }

      const params = {
        per_page: 24,
        page: this.meta.current_page + 1,
      };

      if (this.searchInput) {
        params["search[username]"] = this.searchInput;
      }

      this.$store
        .dispatch(`follows/${this.action}`, { id: this.$user.id, params })
        .then((data) => {
          this.users.push(...data.data);
          this.meta = data.meta;
        })
        .catch(() => {
          this.$toast.error("Error while getting users.");
        });
    },

    displayUser(user) {
      this.user = user;
    },

    resetData() {
      this.users = [];
      this.meta = {
        current_page: 0,
      };
    },
  },
};
</script>

<style lang="scss">
.user__list_container {
  display: flex;
  flex-wrap: wrap;
}
</style>
