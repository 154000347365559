<template>
  <div class="widget card pa">
    <div style="position: absolute; top: 10px; right: 10px">
      <UserMenu :user="user" />
    </div>

    <!-- Image, username -->
    <div class="d-flex align-center">
      <!-- Avatar -->
      <avatar-input
        class="mr-4"
        :value="user.image"
        size="60"
        :read-only="user.id !== $user.id"
        :loading="$loading('auth/updateUser')"
        @input="updateImage"
      />

      <!-- Username -->
      <div class="username font-size-18 font-weight-medium">
        {{ user.username }}
      </div>
    </div>

    <!-- Actions -->
    <div v-if="!isAuthUser && !isBlocked" class="actions mt-5" dense>
      <UserFollowButton
        :user="user"
        small
        unfollowable
        color="secondary"
        block
      />

      <ChatUserButton
        v-if="!isPrivate"
        :user="user"
        small
        color="secondary"
        block
        class="mt-3"
      />
    </div>

    <!-- Followers -->
    <v-row v-if="!isBlocked" dense class="follows text-center mt-5">
      <!-- Folowers modal -->
      <UserFollowsModal :user="user" followers :disabled="isPrivate" />

      <v-col cols="auto" class="pt-3">
        <v-icon color="primary" size="8">mdi-circle</v-icon>
      </v-col>

      <!-- Foloowers modal -->
      <UserFollowsModal :user="user" following :disabled="isPrivate" />
    </v-row>

    <template v-if="canView">
      <!-- Divider -->
      <v-divider class="my-5" />

      <!-- Fields -->
      <template v-for="field in fields">
        <div v-if="field.value" :key="field.label" class="field d-flex">
          <!-- Icon -->
          <div class="mr-3">
            <v-icon color="primary">{{ field.icon }}</v-icon>
          </div>

          <div>
            <!-- Label -->
            <label class="primary--text">{{ field.label }}</label>

            <!-- Value -->
            <div class="font-weight-medium">{{ field.value }}</div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import ChatUserButton from "@/components/app/chat/ChatUserButton.vue";
import UserFollowButton from "./UserFollowButton.vue";
import UserFollowsModal from "./UserFollowsModal.vue";
import UserMenu from "./UserMenu.vue";

export default {
  components: { ChatUserButton, UserFollowButton, UserFollowsModal, UserMenu },

  props: {
    user: Object,
  },

  computed: {
    canView() {
      return !this.isPrivate && !this.isBlocked;
    },

    isAuthUser() {
      return this.user.id === this.$user.id;
    },

    isBlocked() {
      return (
        this.user.is_blocked ||
        this.$user.blocked_users?.includes(String(this.user.id))
      );
    },

    isPrivate() {
      return (
        !this.isAuthUser &&
        this.user.profile_type === "private" &&
        !this.user.is_followed
      );
    },

    fields() {
      return this.user
        ? [
            {
              icon: "mdi-earth",
              label: this.$t("common.country"),
              value: this.user.country,
            },
            {
              icon: "mdi-soccer",
              label: this.$t("clubs.favClub"),
              value: this.user.favourite_football_club?.name,
            },
          ]
        : [];
    },
  },

  methods: {
    updateImage(image) {
      this.$store.dispatch("auth/updateUser", { image }).catch(() => {
        this.$toast.error("Error while updating image.");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.follow-count {
  font-size: 24px;
}

.field:not(:last-of-type) {
  margin-bottom: 10px;
}
</style>
