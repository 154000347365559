<template>
  <v-menu
    v-model="menu"
    bottom
    left
    offset-y
    :close-on-content-click="false"
    content-class="menu-modal"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" class="text--gray-light">
        mdi-dots-vertical
      </v-icon>
    </template>

    <v-card class="d-flex flex-column ebony">
      <!-- Copy link -->
      <v-btn color="secondary" small class="justify-start" @click="copyLink">
        <v-icon left>mdi-content-copy</v-icon>
        {{ $t("common.copyLink") }}
      </v-btn>

      <!-- Report modal -->
      <ReportModal
        v-if="user.id !== $user.id"
        field="user_id"
        category="user"
        :id="user.id"
        @close="menu = false"
      />

      <!-- Block -->
      <UserBlockButton
        :user="user"
        color="secondary"
        small
        class="justify-start"
      />
    </v-card>
  </v-menu>
</template>

<script>
import ReportModal from "@/components/app/reports/ReportModal.vue";
import UserBlockButton from "@/components/app/users/UserBlockButton.vue";

export default {
  components: { ReportModal, UserBlockButton },

  props: {
    user: Object,
  },

  data: () => ({
    menu: false,
  }),

  methods: {
    copyLink() {
      const route = this.$router.resolve({
        name: "app.user",
        params: { id: this.user.username },
      });
      const link = window.location.origin + route.href;
      navigator.clipboard.writeText(link);

      this.$toast.success(this.$t("common.linkCopied"));
      this.menu = false;
    },
  },
};
</script>

<style></style>
