<template>
  <div v-if="user" class="user-item">
    <!-- <router-link :to="{ name: 'app.user', params: { id: user.username } }"> -->
    <v-img
      :src="$utils.userImage(user, '120x140')"
      width="120"
      height="140"
      :alt="user.username"
      @click="showUserCard"
      style="cursor: pointer"
    />
    <!-- </router-link> -->

    <router-link
      class="name d-flex btn-basic flex-grow-1 my-3 my-xl-5"
      :to="{ name: 'app.user', params: { id: user.username } }"
    >
      {{ user.username }}
    </router-link>

    <UserFollowButton
      :user="user"
      color="secondary"
      block
      x-small
      unfollowable
      class="mt-2"
      textOnly
    />
  </div>
</template>

<script>
import UserFollowButton from "./UserFollowButton.vue";

export default {
  components: { UserFollowButton },

  props: {
    user: Object,
  },

  emits: ["show-user-card"],

  methods: {
    showUserCard() {
      this.$emit("show-user-card", this.user);
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.user-item {
  max-width: 120px;
  overflow: hidden;
}
.user-item .v-image {
  border-radius: 9px;
}
</style>
