<template>
  <v-btn
    v-if="user.id !== $user.id"
    v-bind="$attrs"
    :loading="loading"
    @click="onClick"
  >
    <v-icon left>mdi-account-cancel</v-icon>
    {{ isBlocked ? $t("common.unblock") : $t("common.block") }}
  </v-btn>
</template>

<script>
export default {
  props: {
    user: Object,
  },

  computed: {
    isBlocked() {
      return this.$user.blocked_users?.includes(String(this.user.id));
    },

    loading() {
      return (
        this.$loading("users/block") === this.user.id ||
        this.$loading("users/unblock") === this.user.id
      );
    },
  },

  methods: {
    async onClick() {
      const action = this.isBlocked ? "users/unblock" : "users/block";

      if (
        !this.isBlocked &&
        !(await this.$confirm({ text: this.$t("users.blockConfirm") }))
      ) {
        return;
      }

      this.$store
        .dispatch(action, this.user.id)
        .then((user) => {
          this.$set(this.$user, "blocked_users", user.blocked_users);
        })
        .catch(() => {
          this.$toast.error("Error while (un)blocking user.");
        });
    },
  },
};
</script>

<style></style>
