<template>
  <v-dialog v-model="dialog" width="500" :disabled="disabled">
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <v-col v-bind="attrs" v-on="on">
        <span class="follow-count">{{
          followers ? user.followers_count : user.following_count
        }}</span>
        <label class="d-block font-size-14 text--secondary cursor-pointer">
          {{ followers ? $t("users.followers") : $t("users.following") }}
        </label>
      </v-col>
    </template>

    <v-card>
      <v-card-title class="text-h6 primary--text">
        <!-- Tabs -->
        <v-btn
          class="mr-4"
          @click="selectedTab = 'follows/fetchFollowers'"
          :color="
            this.selectedTab === 'follows/fetchFollowers'
              ? 'primary'
              : 'secondary'
          "
          rounded
        >
          {{ $t("users.followers") }}
        </v-btn>
        <v-btn
          @click="selectedTab = 'follows/fetchFollowings'"
          :color="
            this.selectedTab === 'follows/fetchFollowings'
              ? 'primary'
              : 'secondary'
          "
          rounded
        >
          {{ $t("users.following") }}
        </v-btn>

        <v-spacer />

        <close-button @click="dialog = false" />
      </v-card-title>

      <v-divider />

      <!-- Main -->
      <v-card-text>
        <!-- Loader -->
        <template v-if="!meta.current_page && $loading(selectedTab)">
          <spinner medium center style="height: 200px" />
        </template>

        <!-- Users -->
        <template v-else-if="users.length">
          <UserRow v-for="user in users" :key="user.id" :user="user" />

          <div v-if="$utils.canLoadMore(meta)" class="load-more text-center">
            <v-btn
              color="primary"
              :loading="meta && $loading(selectedTab)"
              small
              rounded
              @click="fetchData"
            >
              {{ $t("common.loadMore") }}
            </v-btn>
          </div>
        </template>

        <!-- Empty -->
        <template v-else>
          <v-alert text class="mb-0">
            {{ $t("common.noDataFound") }}
          </v-alert>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import UserRow from "./UserRow.vue";

export default {
  components: { UserRow },

  props: {
    user: Object,
    followers: Boolean,
    following: Boolean,
    disabled: Boolean,
  },

  data: () => ({
    dialog: false,
    users: [],
    meta: {
      current_page: 0,
    },
    selectedTab: "",
  }),

  created() {
    if (this.followers) {
      this.selectedTab = "follows/fetchFollowers";
    } else {
      this.selectedTab = "follows/fetchFollowings";
    }
  },

  watch: {
    selectedTab() {
      if (this.dialog) {
        this.reset();
        this.fetchData();
      }
    },
    dialog(val) {
      val ? this.fetchData() : this.reset();
    },
  },

  methods: {
    fetchData() {
      const params = {
        ...this.params,
        page: this.meta.current_page + 1,
      };
      this.$store
        .dispatch(this.selectedTab, { id: this.user.id, params })
        .then((res) => {
          this.meta = res.meta;
          this.users.push(...res.data);
        })
        .catch(() => {
          this.$toast.error("Error while fetching users.");
        });
    },

    reset() {
      this.users = [];
      this.meta = {
        current_page: 0,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.user-row:not(:last-of-type) {
  margin-bottom: 20px;
}
</style>
